<template>
  <div>
    <!-- 卡片视图区域 -->
    <el-card>
      <!--  -->
      <el-row :gutter="20" style="margin-bottom: 20px;">
        <el-col :span="3">
          <el-input placeholder="请输入真实姓名" clearable v-model="queryInfo.trueName" @clear="getSettlementList" @change="current">
          </el-input>
        </el-col>
        <el-col :span="3">
          <el-input placeholder="请输入手机号" clearable v-model="queryInfo.phone" @clear="getSettlementList" @change="current">
          </el-input>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" icon="el-icon-search" @click="getSettlementList">查询</el-button>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" icon="el-icon-refresh-left" @click="chongzhi">重置</el-button>
        </el-col>
        <el-col :span="2" style="margin-right: 30px;" v-if="xinzeng">
          <el-button type="success" icon="el-icon-plus" @click="showSettlement">新增商户</el-button>
        </el-col>
        <!-- <el-col :span="1">
            <el-button type="info" @click="rest_all">重置</el-button>
          </el-col> -->
      </el-row>
      <el-row :gutter="20" v-if="daochu">

        <!-- <el-col :span="1">
          <el-button>导出账单</el-button>
        </el-col> -->
      </el-row>
      <!-- table表格区域 -->
      <el-table :data="settlementList" border @selection-change="handleSelectionChange" @row-dblclick="dbclick"
        empty-text="暂无数据">
        <el-table-column align="center" width="55" type="selection"></el-table-column>
        <el-table-column align="center" type="expand">
          <template slot-scope="scope">
            <el-form label-position="left" class="demo-table-expand">
              <el-row :gutter="20" type="flex" class="row-bg" justify="space-around">
                <el-col :span="8">
                  <el-form-item label="真实姓名：">
                    <span>{{ scope.row.trueName }}</span>
                  </el-form-item>
                  <el-form-item label="服务率比率：">
                    <span>{{ scope.row.serviceRate }}%</span>
                  </el-form-item>
                  <el-form-item label="手机号：">
                    <span>{{ scope.row.phone }}</span>
                  </el-form-item>
                  <el-form-item label="备注说明：" v-if="scope.row.des != ''">
                    <span>{{ scope.row.des }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="余额：">
                    <span>{{ scope.row.money }}</span>
                  </el-form-item>
                  <el-form-item label="创建时间：">
                    <span>{{ scope.row.createTime }}</span>
                  </el-form-item>
                  <el-form-item label="支付卡号：">
                    <span>{{ scope.row.bankCard }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="所属银行：">
                    <span>{{ scope.row.bankName }}</span>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column align="center" label="商户名称" prop="merchantName">
          <template slot-scope="scope">
            <div style="display: flex;justify-content: flex-start;"> {{ scope.row.merchantName
              }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="真实姓名" prop="trueName">
          <template slot-scope="scope">
            <div style="display: flex;justify-content: flex-start;"> {{ scope.row.trueName
              }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="手机号" prop="phone" width="120"></el-table-column>
        <el-table-column align="center" label="收费类型" prop="type" width="100"></el-table-column>
        <el-table-column align="center" label="服务费率(%)" prop="serviceRate" width="100"></el-table-column>
        <el-table-column align="center" label="余额(元)" prop="money" width="100">
          <template slot-scope="scope">
            <el-tag type="success"> ￥{{ scope.row.money }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="结算类型" prop="type" width="100">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.type != 0 ? scope.row.type = '提现' : scope.row.type = '清分'"> {{ scope.row.type
              }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="状态" prop="status" v-if="bianji" width="100">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status" :active-color="scope.row.status == false ? '#ff4949' : '#13ce66'"
              :inactive-color="scope.row.status == false ? '#ff4949' : '#13ce66'"
              @change="userStateChange(scope.row)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column align="center" label="更新时间" prop="updateTime" width="100"></el-table-column>
        <el-table-column align="left" label="操作（禁用状态下不可提现！）" width="360px">
          <template slot-scope="scope">
            <el-button size="small" type="primary" icon="el-icon-edit" :disabled="scope.row.b_j_anniu"
              @click="showSettlement(scope.row)" v-if="bianji">编辑</el-button>
            <el-button size="small" type="warning" v-if="yuyuetixian == true"
              @click="showExpend(scope.row)">账单明细</el-button>
            <el-button size="small" type="danger" v-if="yuyuetixian == true && scope.row.y_y_t_x_anniu == false"
              @click="showCashOut(scope.row)">预约提现</el-button>
            <el-button size="small" type="danger" v-else disabled>预约提现</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页导航区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.page" :page-sizes="[8, 20, 50, 100]" :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper" :total="total" background>
      </el-pagination>
    </el-card>
    <!-- 添加商户&&编辑商户的对话框 -->
    <el-dialog :title="title" :visible.sync="editDialogVisible" width="38%" @close="editDialogClosed" append-to-body
      :close-on-click-modal="false">
      <!-- 内容主体区域 -->
      <el-form :model="settlementForm" :rules="settlementRules" ref="settlementRef" label-width="100px">
        <el-row :gutter="24">
          <el-col :span="12">

            <el-form-item label="商户名称" prop="merchantName">
              <el-input v-model="settlementForm.merchantName" placeholder="请输入商户名称"></el-input>
              <!-- <el-select v-model="settlementForm.merchantName" placeholder="请选择商户（可输入）" style="width: 100%;" filterable>
                <el-option v-for="item in operatorList" :key="item.adminId" :label="item.adminName" :value="item.adminId">
                </el-option>
              </el-select> -->
            </el-form-item>
            <el-form-item label="真实姓名" prop="trueName">
              <el-input v-model="settlementForm.trueName" placeholder="请输入真实姓名"></el-input>
            </el-form-item>
            <el-form-item label="收款银行" prop="bankName">
              <el-input v-model="settlementForm.bankName" placeholder="请输入银行名称"></el-input>
            </el-form-item>
            <el-form-item label="收费类型" prop="type">
              <el-select v-model="settlementForm.type" placeholder="请选择收费类型（可输入）" style="width: 100%;" filterable>
                <el-option v-for="item in typeList" :key="item.type" :label="item.typeName" :value="item.type">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注说明" prop="des">
              <el-input v-model="settlementForm.des" placeholder="请输入备注说明"></el-input>
            </el-form-item>

          </el-col>
          <el-col :span="12">
            <!-- <el-form-item label="小程序" prop="wechatId">
              <el-select v-model="settlementForm.wechatId" placeholder="请选择小程序" style="width: 100%;">
                <el-option v-for="item in wechartAppletList" :key="item.id" :label="item.appName" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item label="联系电话" prop="phone">
              <el-input v-model="settlementForm.phone" placeholder="请输入电话号码"></el-input>
            </el-form-item>
            <el-form-item label="收款卡号" prop="bankCard">
              <el-input v-model="settlementForm.bankCard" placeholder="请输入收款卡号"></el-input>
            </el-form-item>
            <el-form-item label="提现商户" prop="adminId"> <el-select v-model="settlementForm.adminId"
                placeholder="请选择商户（可输入）" style="width: 100%;" filterable>
                <el-option v-for="item in operatorList" :key="item.adminId" :label="item.adminName"
                  :value="item.adminId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="费率" prop="serviceRate">
              <el-input-number v-model="settlementForm.serviceRate" controls-position="right" :min="0"
                :max="100"></el-input-number>
              <span style="margin-left: 8px;">%</span>
            </el-form-item>
            <!-- <el-form-item label="状态" prop="status" required>
              <el-switch v-model="settlementForm.status" active-text="启用" inactive-text="禁用"
                style="margin-left: 8px;"></el-switch>
            </el-form-item> -->
          </el-col>
        </el-row>
      </el-form>
      <!-- 底部按钮区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogClosed">取 消</el-button>
        <el-button type="primary" v-debounce="editSettlement">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 账单明细的对话框 -->
    <el-dialog title="账单明细" :visible.sync="expendDialogVisible" width="80%" @close="expendDialogClosed" append-to-body>
      <!-- 内容主体区域 -->
      <el-table :data="expendList" border empty-text="暂无数据">
        <el-table-column align="center" label="商户名称" prop="adminName"></el-table-column>
        <el-table-column align="center" label="初始金额" prop="startMoney" width="120">
          <template slot-scope="scope">
            <span>￥{{ jisuan(scope.row.startMoney) }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="变动金额" prop="changeMoney" width="120">
          <template slot-scope="scope">
            <span>￥{{ jisuan(scope.row.changeMoney) }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="余额" prop="newMoney" width="120">
          <template slot-scope="scope">
            <span>￥{{ jisuan(scope.row.newMoney) }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="结算类型" prop="type" width="120">
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.type == 0 ? 'danger' : scope.row.type == 1 ? 'success' : scope.row.type == 2 ? 'warning' : ''">{{
        scope.row.type == 0 ? '提现' : scope.row.type == 1 ? '入账' : scope.row.type == 2 ? '补差' : '提现失败' }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="流水单号" prop="billCode" width="260"></el-table-column>
        <el-table-column align="center" label="服务费" prop="servicemoney" width="120">
          <template slot-scope="scope">
            <span>￥{{ jisuan(scope.row.servicemoney) }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="订单金额" prop="orderMoney" width="120">
          <template slot-scope="scope">
            <span>￥{{ jisuan(scope.row.orderMoney) }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="备注" prop="des">
          <template slot-scope="scope">
            <span>{{ scope.row.des }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="入账时间" prop="createTime" width="140"></el-table-column>
      </el-table>
      <!-- 分页导航区域 -->
      <el-pagination @size-change="zhangdanChange" @current-change="zhangdanCurrentChange"
        :current-page="zhangdanInfo.page" :page-sizes="[8, 20, 50, 100]" :page-size="zhangdanInfo.limit"
        layout="total, sizes, prev, pager, next, jumper" :total="expendTotal" background>
      </el-pagination>
    </el-dialog>
    <!-- 预约提现对话框 -->
    <el-dialog title="预约提现" :visible.sync="cashOutDialogVisible" width="38%" @close="cashOutDialogClosed"
      append-to-body>
      <el-form :model="settlementForm" :rules="settlementRules" ref="settlementRef" label-width="80px">
        <el-form-item label="账户余额" prop="money">
          <el-tag>￥{{ settlementForm.money }}</el-tag>
        </el-form-item>
        <el-form-item label="提现金额">
          <el-input-number v-model="yuyuemoney" controls-position="right" :min="1000" @change="yuyue"
            :step="0.01"></el-input-number>
          <span style="margin-left: 8px;">元</span>
        </el-form-item>
      </el-form>
      <el-radio v-model="settlementForm.contacts">个人银行卡</el-radio>
      <div style="margin: 15px 0;">
        微信收取<span style="color: red;">￥{{ wxsq }}</span> 手续费，实际到账<span style="color: red;">￥{{ sjdz }}</span>
      </div>
      <div style="margin: 15px 0;">
        <span style="font-weight: 700;">手续费规则：</span>
      </div>
      <div>
        <span>1.微信收取<span style="color: red;">0.6%</span>的手续费</span>
      </div>
      <div style="margin: 15px 0;">
        <span>2.预约提现<span style="color: red;">1-3</span>个工作日</span>
      </div>
      <div>
        <span style="color: red;">注：预约提现一般1-3个工作日到账，若未到账，请及时联系售后。</span>
      </div>
      <!-- 底部按钮区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="cashOutDialogClosed">取 消</el-button>
        <el-button type="primary" @click="editCashOut">预约</el-button>
      </span>
    </el-dialog>
    <!-- <el-button :plain="jinggao" @click="open3">{{ mesage }}</el-button> -->
  </div>
</template>

<script>
import caozuo from "@/utils/pubilc.js";
export default {
  name: 'wechartAccountWorld',
  data() {
    // 验证真实姓名的规则
    const checkTrueName = (rule, value, callback) => {
      const reg = /^(?=.{2,4}$)[\u4e00-\u9fa5]+$/
      if (reg.test(value)) {
        return callback()
      }
      callback(new Error('真实姓名由2-4个汉字组成'))
    }
    // 验证手机号的规则
    const checkMobile = (rule, value, callback) => {
      // 验证手机号的正则表达式
      const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/
      if (regMobile.test(value)) {
        // 正确的电话号码
        return callback()
      }
      callback(new Error('请输入正确的手机号码'))
    }
    // 验证银行卡号的规则
    const checkBankCard = (rule, value, callback) => {
      // 验证手机号的正则表达式
      const regBankCard = /^(\d{10,25})$/
      if (regBankCard.test(value)) {
        return callback()
      }
      callback(new Error('银行卡号一般为10-25位数字'))
    }
    // 验证服务费率的规则
    const checkServiceRate = (rule, value, callback) => {
      // 验证服务费率的正则表达式
      const regServiceRate = /^[0-9]\d*$/
      if (regServiceRate.test(value)) {
        return callback()
      }
      callback(new Error('费率为0-100之间的整数'))
    }
    return {
      tx_id: 0,
      yuyuetixian: false,
      daochu: false,
      xinzeng: false,
      bianji: false,
      typeList: [
        {
          type: 0,
          typeName: '服务费'
        },
        {
          type: 1,
          typeName: '全额消费'
        }
      ],
      caozuoquanxian: false,
      wxsq: 0,
      sjdz: 0,
      num: 1000,
      yuyuemoney: 1000,
      queryInfo: {
        trueName: null,
        phone: null,
        stationName: null,
        stationIds: window.sessionStorage.getItem('stationIds'),
        adminId: window.sessionStorage.getItem('adminId'),
        page: 1,
        limit: 8
      },
      zhangdanInfo: {
        stationLedgerId: null,
        page: 1,
        limit: 8
      },
      // 商户列表
      settlementList: [],
      settlementForm: {
        adminId: '',
        type: 0,
        trueName: '',
        bankCode: '',
        bankName: '',
        openId: '',
        status: 0,
        wechatId: '',
        phone: '',
        bankCard: '',
        serviceRate: '',
        powerStationId: 0,
        stationIds: '',
        money: 0,
        type: 0,
        des: '',
        merchantName: '',
      },
      settlementRules: {
        merchantName: [
          { required: true, message: '请至少选择一项', trigger: 'change' }
        ],
        type: [
          { required: true, message: '请选择你的收费类型', trigger: 'change' }
        ],
        adminId: [
          { required: true, message: '请输入商户名称', trigger: 'change' }
        ],
        trueName: [
          { required: true, message: '真实姓名不能为空', trigger: 'blur' },
        ],
        bankName: [
          { required: true, message: '银行名称不能为空', trigger: 'blur' }
        ],
        openId: [
          { required: true, message: '收款openId不能为空', trigger: 'blur' }
        ],
        wechatId: [
          { required: true, message: '请至少选择一项', trigger: 'change' }
        ],
        phone: [
          { required: true, message: '电话号码不能为空', trigger: 'blur' },
        ],
        bankCard: [
          { required: true, message: '银行卡号不能为空', trigger: 'blur' },
        ],
        serviceRate: [
          { required: true, message: '费率不能为空', trigger: 'blur' },
        ]
      },
      // 总数据条数 
      total: 0,
      expendTotal: 0,
      title: '',
      editDialogVisible: false,
      value: '',
      operatorList: [],
      wechartAppletList: [],
      expendList: [],
      cashOutDialogVisible: false,
      expendDialogVisible: false,
      ids: '',
      typeName: null,
      newMoney: 0,
    }
  },
  created() {
    caozuo(146).then(res => {
      this.xinzeng = res;
    });
    caozuo(145).then(res => {
      this.bianji = res;
    });
    caozuo(147).then(res => {
      this.yuyuetixian = res;
    });
    this.getSettlementList();
    this.getOperatorList();
    if (this.yuyuemoney == 1000) {
      this.wxsq = Number(this.yuyuemoney) * 0.006;
      this.sjdz = Number(this.yuyuemoney) - Number(this.wxsq);
    }
  },
  computed: {
    jisuan: function () {
      return function (obj) {
        return (obj / 100).toFixed(2)
      }
    }
  },
  methods: {
    current(){
this.queryInfo.page=1;
    },
    open3() {
      this.$message({
        message: this.mesage,
        type: 'warning'
      });
    },
    yuyue(e) {
      if (e < 1000) {
        this.jinggao = true;
        this.yuyue = 1000;
      } else if (e > this.settlementForm.money) {
        this.jinggao = true;
        this.$message('提现金额不得大于余额');
      } else {
        this.jinggao = false;
        this.wxsq = (Number(this.yuyuemoney) * 0.006).toFixed(2);
        this.sjdz = Number(this.yuyuemoney) - Number(this.wxsq).toFixed(2);
      }
    },
    chongzhi() {
      this.queryInfo = {
        trueName: null,
        phone: null,
        stationName: null,
        stationIds: window.sessionStorage.getItem('stationIds'),
        adminId: window.sessionStorage.getItem('adminId') * 1,
        page: 1,
        limit: 8
      };
      this.getSettlementList();
    },
    // 根据分页获取对应的商户列表
    async getSettlementList() {
      const { data: res } = await this.$http.get('station-ledger/getAllStationLedger', { params: this.queryInfo })
      if (res.code !== 0) {
        return this.$message.error(res.msg)
      }
      this.settlementList = res.data;

      // this.settlementList.push({ b_j_anniu: false, z_d_m_x_anniu: false, y_y_t_x_anniu: false });
      this.settlementList.forEach(item => {
        item.money = item.money / 100;
        item.status === 0 ? item.status = true : item.status = false;
        item.b_j_anniu = false; item.z_d_m_x_anniu = false; item.y_y_t_x_anniu = false
      })
      this.total = res.count
    },
    // 双击展开事件
    dbclick(row, column, event) {
      if (event.currentTarget.querySelector('.el-table__expand-icon')) {
        event.currentTarget.querySelector('.el-table__expand-icon').click()
      }
    },
    // 监听pageSize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize;
      this.getSettlementList()
    },
    // 监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage;
      this.getSettlementList()
    },
    // 获取运营账户列表数据
    async getOperatorList() {
      const { data: res } = await this.$http.get('admin/getAllAdminName', { params: this.queryInfo })
      if (res.code !== 0) {
        return this.$message.error(res.msg)
      }
      res.data.forEach(item => {
        this.operatorList.push({
          adminId: item.id,
          adminName: item.realname
        })
      });
      // this.operatorList = res.data
    },
    // 展示添加商户和编辑商户对话框
    async showSettlement(row) {
      this.settlementForm.id = row.id;
      if (row.id === '' || row.id === null || row.id === undefined) {
        this.title = '新增商户';
        this.editDialogVisible = true
      } else {
        this.title = '编辑商户'
        await this.$http.get('station-ledger/getStationLedgerById/' + row.id).then(res => {
          row.b_j_anniu = true;
          this.settlementForm = res.data.data;
          this.operatorList.forEach(item => {
            if (item.adminId == res.data.adminId) {
              this.settlementForm.adminId = item.adminName;
            }
          })
          this.settlementForm.money = res.data.data.money / 100;
          this.editDialogVisible = true;
        })
      }
    },
    // 添加商户&&编辑商户方法 
    editSettlement() {
      let that = this;
      if (
        that.settlementForm.merchantName.length == 0 ||
        that.settlementForm.trueName.length == 0 ||
        that.settlementForm.serviceRate.length == 0 ||
        that.settlementForm.phone.length == 0 ||
        that.settlementForm.bankCard.length == 0
        || that.settlementForm.bankName.length == 0 ||
        that.settlementForm.adminId.length == 0
      ) { return that.$message.error('此为必填项') }
      else {
        that.settlementForm.adminId = that.settlementForm.adminId * 1;
        that.settlementForm.status == true ? that.settlementForm.status = 0 : that.settlementForm.status = 1;
        if (that.title == '新增商户') {
          that.settlementForm.status = 0;
          that.$http.post('station-ledger/addStationLedger', that.settlementForm).then(res => {
            if (res.data.code === 0) {
              that.editDialogVisible = false
              that.getSettlementList()
            } else {
              that.$message.error(res.data.msg)
              that.editDialogVisible = false
              that.getSettlementList()
            }
          })
        } else {
          // 编辑商户
          that.$http.post('station-ledger/updStationLedger', that.settlementForm).then(ress => {
            if (ress.data.code == 0) {
              that.$message.success(ress.data.msg);
            } else {
              that.$message.error(ress.data.msg);
            };
            that.editDialogVisible = false;
            that.getSettlementList()
          })
        }
      }
    },
    // 商户状态的更新事件
    async userStateChange(row) {
      if (row.status == true) {
        this.settlementForm.status = 0
      } else if (row.status == false) {
        this.settlementForm.status = 1
      };
      const { data: res } = await this.$http.post(`station-ledger/updateStatusById/${row.id}/${this.settlementForm.status}`)
      if (res.code !== 0) {
        this.$message.error(res.msg)
      } else {
        this.$message.success(res.msg)
        this.getSettlementList()
      }
    },
    // 添加商户&&编辑对话框的关闭事件
    editDialogClosed() {
      this.$refs.settlementRef.resetFields();
      this.editDialogVisible = false;
      this.settlementForm = {};
      this.settlementList.forEach(item => {
        item.b_j_anniu = false;
      })
    },
    // 展示预约提现对话框
    async showCashOut(row) {
      this.cashOutDialogVisible = true;
      this.tx_id = row.id;
      const { data: res } = await this.$http.get('station-ledger/getStationLedgerById/' + row.id)
      if (res.code == 0) {
        row.y_y_t_x_anniu = true;
        this.settlementForm = res.data;
        this.settlementForm.money = res.data.money / 100;
      } else {
        row.y_y_t_x_anniu = false;
        this.$message.error(res.msg)
      }
    },
    // 预约提现的方法
    async editCashOut() {
      const that = this;
      let abc = null;
      abc = that.yuyuemoney;
      that.$http.post('station-ledger/Withdrawal', {
        id: that.settlementForm.id,
        money: abc * 100,
        adminId: window.sessionStorage.getItem('adminId') * 1
      }).then(res => {
        if (res.data.code == 0) {
          that.$message.success(res.data.msg);
          that.getSettlementList();
        } else {
          that.$message.error(res.data.msg)
        }
        that.cashOutDialogVisible = false
      })
    },
    // 预约提现对话框的关闭事件
    cashOutDialogClosed() {
      this.cashOutDialogVisible = false;
      this.settlementList.forEach(item => {
        item.y_y_t_x_anniu = false;
      })
    },
    zhangdanChange(newSize) {
      this.zhangdanInfo.limit = newSize;
      this.xiaofeiliebiao()
    },
    // 监听页码值改变的事件
    zhangdanCurrentChange(newPage) {
      this.zhangdanInfo.page = newPage;
      this.xiaofeiliebiao()
    },
    // 展示消费列表对话框
    showExpend(row) {
      this.zhangdanInfo.page = 1;
      this.expendDialogVisible = true;
      row.z_d_m_x_anniu = true;
      this.zhangdanInfo.stationLedgerId = row.id;
      this.expendList=[];
      this.expendTotal = 0;
      this.xiaofeiliebiao()
    },
    // 消费列表
    async xiaofeiliebiao() {
      const { data: res } = await this.$http.get('station-ledger-log/getStationLedgerLog', {
        params: this.zhangdanInfo
      })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('获取商户消费列表成功！');
      this.expendList = res.data;
      this.expendTotal = res.count;
    },
    // 消费列表对话框的关闭事件
    expendDialogClosed() {
      this.cashOutDialogVisible = false
    },
    // 批量删除的复选框改变事件
    handleSelectionChange(value) {
      if (value.length > 0) {
        this.ids = ''
        value.forEach(item => {
          this.ids += item.id + ','
        })
        this.ids = this.ids.substring(0, this.ids.length - 1)
      } else {
        return false
      }
    },
    // 重置方法
    rest_all() {
      this.queryInfo.trueName = '';
      this.queryInfo.phone = '';
      this.getSettlementList()
    }
  }
}
</script>

<style lang="less" scoped></style>