<template>
  <div>
    <!-- 卡片视图区域 微信用户-->
    <el-card class="card">
      <!--  -->
      <el-form :inline="true" :model="queryInfo" class="form">
        <el-form-item label="手机号:">
          <el-input placeholder="请输入手机号:" clearable v-model="queryInfo.phone" @clear="getWechartList" @change="current">
          </el-input>
        </el-form-item>
        <el-form-item label="车主编号:"><el-input placeholder="请输入车主编号:" clearable v-model="queryInfo.userNumber"
            @clear="getWechartList" @change="current">
          </el-input></el-form-item>
        <el-form-item label="常用电站:" v-if="tab">
          <el-select v-model="changyong" placeholder="请选择电站" @change="chage_zhuangType" filterable clearable
            @clear="qingchu">
            <el-option v-for="item in zhuangTypelist" :key="item.stationIds" :label="item.stationName"
              :value="item.stationIds">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="折扣类型:" v-if="tab">
          <el-select v-model="queryInfo.discounType" placeholder="请选择你的vip折扣方式" filterable clearable @change="current"
            @clear="getWechartList">
            <el-option v-for="item in options_active" :key="item.discounType" :label="item.typeName"
              :value="item.discounType">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户类型:">
          <el-select v-model="queryInfo.type" placeholder="请选择你搜索的用户类型" @change="current" filterable clearable
            @clear="getWechartList">
            <el-option v-for="item in options_user" :key="item.type" :label="item.typeName" :value="item.type">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="getWechartList">
            查询
          </el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-refresh-left" @click="chongzhi">
            重置
          </el-button>
        </el-form-item>
        <el-form-item v-if="Vip">
          <el-button type="success" icon="el-icon-plus" @click="Vipanniu">
            批量添加Vip折扣
          </el-button>
        </el-form-item>
        <el-form-item v-if="daochu">
          <el-button type="primary" @click="daochuanniu">
            导出
          </el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="gengduo">
            更多筛选
          </el-button>
        </el-form-item>
      </el-form>
      <el-table :data="wechartList" border stripe @selection-change="handleSelectionChange" @row-dblclick="dbclick"
        empty-text="暂无数据">
        <el-table-column align="center" type="selection" width="55"></el-table-column>
        <el-table-column align="center" type="expand">
          <template slot-scope="scope">
            <el-form label-position="left" class="demo-table-expand">
              <el-row :gutter="20" type="flex" class="row-bg" justify="space-around">
                <el-col :span="8">
                  <el-form-item label="ID">
                    <span>{{ scope.row.id }}</span>
                  </el-form-item>
                  <el-form-item :label="scope.row.alipayId !== null ? 'alipayId:' : 'openId:'">
                    <span>{{ scope.row.alipayId !== null ? scope.row.alipayId : scope.row.wxOpenId }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="唯一编码：">
                    <span>{{ scope.row.userNumber }}</span>
                  </el-form-item>
                  <el-form-item label="手机号：">
                    <span>{{ scope.row.phone }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="昵称：">
                    <span>{{ scope.row.nickname }}</span>
                  </el-form-item>
                  <el-form-item label="余额（元）：">
                    <el-tag type="success">{{ scope.row.money }}元</el-tag>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column align="center" label="手机号" prop="phone" width="130"></el-table-column>
        <el-table-column align="center" label="用户类型" prop="terminalId" width="80">
          <template slot-scope="scope">
            <img style="width: 40px;
           height: 40px;"
              :src="scope.row.terminalId == 0 ? tupian + '小程序.png' : scope.row.terminalId == 1 ? tupian + 'zfb.png' : scope.row.terminalId == 2 ? tupian + 'zfb.png' : tupian + 'ocpp_APP'"
              alt="">
          </template>
        </el-table-column>
        <el-table-column align="left" label="常用电站" prop="stationName">
          <template slot-scope="scope">
            <span v-if="scope.row.stationName == '' || scope.row.stationName == undefined">--</span>
            <span v-else>{{ scope.row.stationName }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="折扣类型" prop="discounType" width="140">
          <template slot-scope="scope">
            <el-select :disabled="qunxian == true ? false : true" v-model="scope.row.discounType"
              placeholder="请选择你的vip折扣方式" @change="genggai(scope.row)">
              <el-option v-for="item in options_active" :key="item.discounType" :label="item.typeName"
                :value="item.discounType">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column align="center" label="折扣率" prop="discountRate" width="150">
          <template slot-scope="scope">
            <el-input-number size="mini" :disabled="qunxian == true ? false : true" v-model="scope.row.discountRate"
              @change="genggai(scope.row)" :step="1" controls-position="right" :min="0" :max="100"
              label="0-99"></el-input-number>
          </template>
        </el-table-column>
        <el-table-column align="center" label="唯一编码" prop="userNumber" width="140"></el-table-column>
        <el-table-column align="center" label="昵称" prop="nickname"></el-table-column>
        <el-table-column align="center" label="余额（元）" width="100">
          <template slot-scope="scope">
            <el-tag>
              {{ scope.row.money }}元
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="禁用" prop="status" v-if="jinyong" width="100">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status" :active-color="scope.row.status == false ? '#13ce66' : '#ff4949'"
              :inactive-color="scope.row.status == false ? '#13ce66' : '#ff4949'"
              @change="change_type(scope.row)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column align="left" label="操作" width="320">
          <template slot-scope="scope">
            <!-- <el-button v-if="qunxian == true" type="primary" @click="bianji(scope.row)">编辑</el-button> -->
            <el-button type="warning" @click="showExpend(scope.row)">消费记录</el-button>
            <!-- <el-button type="info" @click="delMethods(scope.row.id)" v-if="zengsong == true">删除</el-button> -->
            <el-button type="success" @click="showPresent(scope.row)" v-if="zengsong == true">赠送</el-button>
          </template>
        </el-table-column>
        <div slot="empty" class="empty">
          <span>暂无数据</span>
        </div>
      </el-table>
      <!-- 分页导航区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.page" :page-sizes="[8, 20, 50, 100]" :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper" :total="total" background>
      </el-pagination>
    </el-card>
    <!-- VIP的对话框 -->
    <el-dialog :title="titles" :visible.sync="vipdialoag" width="40%" @close="qx_vipdialoag" append-to-body
      :close-on-click-modal="false">
      <div>
        <el-form :model="active" label-width="140px" ref="activeRef">
          <div class="center" style="display: flex;flex-direction: column;">
            <!-- <div>
              <el-form-item label="活动名称:" prop="usingType">
                <el-input placeholder="请输入活动名称:" clearable v-model="active.phone" @clear="getWechartList">
                </el-input>
              </el-form-item>
            </div> -->
            <div
              style="display: flex;justify-content: center;padding-bottom: 20px;color: red;font-size: 14px;font-weight: bolder;">
              *注：0免费,1-99为折扣百分比,100为正常收款</div>
            <div :span="6" style="display: flex;flex-direction: row;">
              <el-form-item label="折扣方式:" prop="discounType">
                <el-select style="margin-right: 50px;" v-model="active.discounType" placeholder="请选择你的vip折扣方式">
                  <el-option v-for="item in options_active" :key="item.discounType" :label="item.typeName"
                    :value="item.discounType">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="折扣" prop="discountRate">
                <el-input-number size="medium" v-model="active.discountRate" :step="1" controls-position="right"
                  :min="0" :max="100" label="0-99"></el-input-number>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="qx_vipdialoag">取 消</el-button>
        <el-button type="primary" @click="qxvipdialoag">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 导出的对话框 -->
    <el-dialog title="导出" :visible.sync="daochu_dialoag" width="30%">
      <div class="cen">
        <el-form :model="daochuForm" label-width="140px">
          <el-form-item label="类型:" prop="usingType">
            <el-select v-model="daochuForm.usingType" placeholder="请选择导出内容" filterable clearable style="flex:7">
              <el-option v-for="item in typelist" :key="item.usingType" :label="item.typeName"
                :value="item.usingType"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="日期">
            <el-date-picker v-model="value1" type="daterange" :picker-options="pickerOptions" range-separator="至"
              @blur="pickBlur" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
              format="yyyy-MM-dd" @change="data_change">
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="qx_dialoag">取 消</el-button>
        <el-button type="primary" @click="qd_dialoag">
          <i class="el-icon-loading" v-if="loading == true"></i>
          <span>确 定</span>
        </el-button>
      </span>
    </el-dialog>
    <!-- 赠送的对话框 -->
    <el-dialog title="余额赠送" :visible.sync="editDialogVisible" width="30%" @close="editDialogClosed" append-to-body
      :close-on-click-modal="false">
      <!-- 内容主体区域 -->
      <el-form :model="wechartForm" ref="editFormRef" label-width="140px">
        <el-form-item label="总余额（元）：" prop="money">
          <el-tag>￥{{ wechartForm.money / 100 }}元</el-tag>
        </el-form-item>
        <!-- <el-form-item label="已赠金额" prop="giveMoney">
          <el-tag>￥{{ wechartForm.giveMoney/100 }}</el-tag>
        </el-form-item> -->
        <el-form-item label="赠送金额（元）：" prop="num">
          <el-input-number :precision="2" :step="0.1" v-model="num" controls-position="right"
            :min="1"></el-input-number>
          <span style="margin-left: 10px;">元</span>
        </el-form-item>
      </el-form>
      <!-- 底部按钮区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogClosed">取 消</el-button>
        <el-button type="primary" v-debounce="editPresent">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 消费记录的对话框 -->
    <el-dialog title="消费记录" :visible.sync="expendDialogVisible" width="80%" @close="expendDialogClosed" append-to-body>
      <!-- 内容主体区域 -->
      <el-table :data="xiaofeilist" border>
        <el-table-column align="center" label="用户ID" prop="id" width="160">
          <template scope="scope">
            <span>{{ scope.row.id }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="唯一编码" prop="userNumber" width="200"></el-table-column>
        <el-table-column align="center" label="变动金额" prop="changMoney" width="160">
          <template slot-scope="scope">
            <el-tag  :type="scope.row.usingType=='0'?'info':scope.row.usingType==1?'success':scope.row.usingType==2?'warning':scope.row.usingType==3?'':'danger'">￥{{ scope.row.changMoney / 100 }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作类型" prop="usingType" width="100">
          <template slot-scope="scope">
            <el-tag :type="scope.row.usingType=='0'?'info':scope.row.usingType==1?'success':scope.row.usingType==2?'warning':scope.row.usingType==3?'':'danger'">
              {{ scope.row.usingType == '0' ? '消费' : scope.row.usingType == '1' ? '充值' : scope.row.usingType ==
        '2' ? '退款' : scope.row.usingType == '3' ? '赠送' : '欠费' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="流水单号" prop="billCode" width="320"></el-table-column>
        <el-table-column align="center" label="备注说明" prop="des"></el-table-column>
        <el-table-column align="center" label="操作时间" width="160">
          <template slot-scope="scope">
            <span v-if="scope.row.dcreateTime != null">{{ scope.row.dcreateTime }}</span><span v-else>{{
        scope.row.createTime }}</span>
          </template>
        </el-table-column>
        <div slot="empty" class="empty">
          <span>暂无数据</span>
        </div>
      </el-table>
      <!-- 分页导航区域 -->
      <el-pagination @size-change="expendSizeChange" @current-change="expendCurrentChange"
        :current-page="xiaofeiInfo.page" :page-sizes="[8, 20, 50, 100]" :page-size="xiaofeiInfo.limit"
        layout="total, sizes, prev, pager, next, jumper" :total="xiaofeiTotal" background>
      </el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import caozuo from "@/utils/pubilc.js";
import { saveBlobToFile } from '@/utils/saveBlobToFile';
export default {
  name: 'wechartAccountWorld',
  data() {
    return {
      tab: false,
      tupian: this.$imgUrl,
      loading: false,
      titles: '添加Vip用户',
      qunxian: false,
      active: {
        ids: '',
        discounType: null,
        discounType: 3,
        discountRate: 100
      },
      vipdialoag: false,
      yonghu_dialoag: false,
      Vip: false,
      value1: ['', ''],
      changyong: '',
      // 折扣类型
      options_active: [{
        discounType: 1,
        typeName: '电费'
      }, {
        discounType: 2,
        typeName: '服务费'
      }, {
        discounType: 3,
        typeName: '订单金额'
      }],
      options_user: [
        {
          type: 0,
          typeName: '全部'
        }, {
          type: 1,
          typeName: '微信'
        },
        {
          type: 2,
          typeName: '支付宝'
        }, {
          type: 3,
          typeName: 'APP'
        },
        {
          type: 4,
          typeName: 'ocpp_APP'
        }
      ],
      daochuForm: {
        startTime: '',
        endTime: '',
        usingType: '',
        adminId: Number(window.sessionStorage.getItem('adminId')),
      },
      pickerMinDate: '',
      pickerOptions: {
        onPick: obj => {
          this.pickerMinDate = new Date(obj.minDate).getTime()
          const start = this.formatDate(obj.minDate, 'start')
          const end = this.formatDate(obj.maxDate, 'end')
          obj.maxDate && (this.value1 = [start, end])
        },
        disabledDate: time => {
          if (this.pickerMinDate) {
            const oneMonth = 1000 * 3600 * 24 * 31
            const maxTime = this.pickerMinDate + oneMonth
            const mixTime = this.pickerMinDate - oneMonth + 1000 * 3600 * 24
            const tomorrow = new Date(this.formatDate(new Date().getTime() + 1000 * 3600 * 24, 'start'))
            return time.getTime() >= tomorrow || time.getTime() >= maxTime || time.getTime() < mixTime
          }
        }
      },
      typelist: [
        {
          usingType: '0',
          typeName: '消费'
        },
        {
          usingType: '1',
          typeName: '充值'
        },
        {
          usingType: '2',
          typeName: '退款'
        },
        {
          usingType: '3',
          typeName: '赠送'
        }
      ],
      daochu_dialoag: false,
      daochu: false,
      num: 0,
      zengsong: false,
      jinyong: false,
      zhuangTypelist: [],
      queryInfo: {
        nickName: '',
        userName: '',
        userNumber: '',
        stationIds: '',
        phone: '',
        adminId: window.sessionStorage.getItem('adminId'),
        roleId: window.sessionStorage.getItem('roleId'),
        page: 1,
        limit: 8,
        discounType: null,
        type: 0,
        stationMode: '0,1,2'
      },
      xiaofeiInfo: {
        nickName: '',
        userName: '',
        userNumber: '',
        // stationId:0,
        phone: '',
        adminId: window.sessionStorage.getItem('adminId'),
        page: 1,
        limit: 8
      },
      // 微信用户列表
      wechartList: [],
      wechartForm: {},
      // 总数据条数
      total: 0,
      expendTotal: 0,
      xiaofeiTotal: 0,
      title: '',
      editDialogVisible: false,
      value: '',
      expendList: [],
      expendDialogVisible: false,
      ids: '',
      value: '',
      Idcard: '',
      xiaofeilist: [],
      caozuoquanxian: false,
      xiaofei: {
        page: 1,
        limit: 8,
        userNumber: null,
        adminId: window.sessionStorage.getItem('adminId')
      },
      dangqian: { id: 0, status: 0 },
      xf: null
    }
  },
  created() {
    const that = this;
    caozuo(151).then(res => {
      that.zengsong = res;
    });
    caozuo(98).then(res => {
      that.jinyong = res;
    });
    caozuo(98).then(res => {
      that.daochu = res;
    });
    caozuo(167).then(res => {
      that.Vip = res;
    });
    caozuo(169).then(res => {
      that.qunxian = res;
    });
    that.stationNameMethods();
    that.getWechartList();
  },
  methods: {
    // 删除
    delMethods(row) {
      this.$http.post(`charge-user-info/delChargeUserInfoById/${row}`).then(res => {
        this.$message({
          message: res.data.msg,
          type: res.data.code !== 0 ? 'warning' : 'success'
        });
        if(res.data.code !== 0)return; 
        this.getWechartList();
      })
    },
    current() {
      this.queryInfo.page = 1;
    },
    gengduo() {
      this.tab = !this.tab;
    },
    pickBlur() {
      this.pickerMinDate = ''
    },
    stationNameMethods() {
      const that = this;
      that.$http.get('station-info/getAllStationInfoName', {
        params: {
          roleId: window.sessionStorage.getItem('roleId'),
          stationIds: window.sessionStorage.getItem('stationIds'),
          stationMode: '0,1,2'
        }
      }).then(res => {
        if (res.data.code == 0) {
          res.data.data.forEach(item => {
            that.zhuangTypelist.push({
              stationIds: item.id,
              stationName: item.stationName
            })
          });
          that.queryInfo.stationIds = '';
        }
      });
    },
    formatDate(datetime, type) {
      const time = new Date(datetime)
      const year = time.getFullYear()
      const month = (time.getMonth() + 1).toString().padStart(2, '0')
      const date = (time.getDate()).toString().padStart(2, '0')
      return type === 'start' ? year + '-' + month + '-' + date + ' ' + '00' + ':' + '00' + ':' + '00' : year + '-' + month + '-' + date + ' ' + '23' + ':' + '59' + ':' + '59'
    },
    getDate() {
      const start = new Date()
      this.value1[0] = this.formatDate(start.getTime() - 1000 * 3600 * 24 * 30, 'start')
      this.value1[1] = this.formatDate(start.getTime(), 'end')
    },
    qingchu() {
      this.queryInfo.stationIds = this.changyong;
      this.getWechartList();
    },
    chongzhi() {
      this.queryInfo = {
        nickName: '',
        userName: '',
        userNumber: '',
        stationIds: '',
        phone: '',
        adminId: window.sessionStorage.getItem('adminId'),
        roleId: window.sessionStorage.getItem('roleId'),
        type: 0,
        stationMode: '0,1,2',
        page: 1,
        limit: 8,
        discounType: null,
      };
      this.changyong = '';
      this.value1 = [];
      this.pickerMinDate = '';
      this.getWechartList()
    },
    qx_vipdialoag() {
      this.active = {};
      this.vipdialoag = false;
    },
    async bianji(row) {
      this.titles = '编辑Vip用户';
      // await this.$http.get(`charge-user-info/getChargeUserInfoById/${row.id}`).then(res => {
      this.vipdialoag = true;
      if (res.data.code == 0) {
        this.$message.success(res.data.msg);
      } else {
        this.$message.error(res.data.msg)
      }
      // })
    },
    // 批量修改用户Vip
    genggai(row) {
      this.active.ids = String(row.id);
      this.active.discounType = row.discounType;
      this.active.discountRate = row.discountRate;
      this.$http.post(`charge-user-info/updateVIPByIds?ids=${this.active.ids}&discounType=${this.active.discounType}&discountRate=${this.active.discountRate}&adminId=${window.sessionStorage.getItem('adminId') * 1}`).then(res => {
        if (res.data.code == 0) {
          this.active = {};
          this.$message.success(res.data.msg);
        } else {
          this.$message.error(res.data.msg)
        }
      })

    },
    // 添加Vip用户
    qxvipdialoag() {
      this.vipdialoag = false;
      this.$refs.activeRef.validate(async valid => {
        if (!valid) return
        if (this.titles == '添加Vip用户') {
          if (this.ids != '') {
            this.active.ids = this.ids;
            this.$http.post(`charge-user-info/updateVIPByIds?ids=${this.active.ids}&discounType=${this.active.discounType}&discountRate=${this.active.discountRate}&adminId=${window.sessionStorage.getItem('adminId') * 1}`).then(res => {
              if (res.data.code == 0) {
                this.$message.success(res.data.msg);
              } else {
                this.$message.error(res.data.msg);
              }
            });
          } else {
            this.$message.error('请先勾选表格中的vip用户')
          }
        }
      })
    },
    // VIP按钮
    Vipanniu() {
      this.vipdialoag = true;
    },
    yonghu() {
      this.yonghu_dialoag = true;
    },
    data_change(e) {
      if (e.length > 0) {
        this.daochuForm.startTime = e[0];
        this.daochuForm.endTime = e[1];
        if (this.daochuForm.startTime == this.daochuForm.endTime) {
          this.$message.error('起始时间不得为同一天！');
          this.daochuForm.startTime = ''
          this.daochuForm.endTime = ''
        }
      } else {
        this.daochuForm.startTime = '';
        this.daochuForm.endTime = '';
      }
    },
    // daochu_change(e) {
    //   this.daochuForm.usingType = e.toString();
    // },
    // 取消导出
    qx_dialoag() {
      const that = this;
      that.daochuForm = {};
      that.value1 = [];
      this.loading = false;
      that.daochu_dialoag = false;
    },
    // 确定导出
    async qd_dialoag() {
      this.loading = true;
      this.daochuForm.adminId = Number(window.sessionStorage.getItem('adminId'));
      const { data: res } = await this.$http({
        url: 'charge-user-info/export',
        method: 'get',
        params: this.daochuForm,
        responseType: 'blob'
      })
      saveBlobToFile(res, '个人账户')
      if (res) {
        this.$message.success('文件导出成功')
      } else {
        this.$message.error('文件导出失败')
      };
      this.loading = false;
      this.daochu_dialoag = false;
      this.value1 = [];
    },
    // 导出
    daochuanniu() {
      this.daochu_dialoag = true;
    },
    //常用电站
    chage_zhuangType(e) {
      this.queryInfo.stationIds = e;
      this.queryInfo.page = 1;
    },
    // 禁用状态(实时更新)
    change_type(row) {
      this.dangqian.id = row.id;
      if (row.status == true) {
        this.dangqian.status = 1;
      } else if (row.status == false) {
        this.dangqian.status = 0;
      }
      this.$http.post(`charge-user-info/updateStatusById/${this.dangqian.id}/${this.dangqian.status}`).then(res => {
        this.$message.success(res.data.msg);
        this.getWechartList()
      }).catch(res => {
        this.$message(res.data.msg)
      })
    },
    reset() {
      this.queryInfo.phone = '';
      this.getWechartList()
    },
    changeTime(value) {
      if (value) {
        this.queryInfo.startTime = value[0]
        this.queryInfo.endTime = value[1]
        if (this.queryInfo.startTime == this.queryInfo.endTime) {
          this.$message.error('起始时间不得为同一天！');
          this.queryInfo.startTime = ''
          this.queryInfo.endTime = ''
        }
      } else {
        console.log(value)
        this.queryInfo.startTime = null
        this.queryInfo.endTime = null
        this.getWechartList()
      }
    },
    // 根据分页获取对应的微信用户列表
    async getWechartList() {
      const { data: res } = await this.$http.get('charge-user-info/getAllChargeUserInfo', { params: this.queryInfo })
      if (res.code !== 0) {
        return this.$message.error(res.msg)
      }
      this.wechartList = res.data;
      this.wechartList.forEach(item => {
        item.money = item.money / 100;
        if (item.status == 1) {
          item.status = true
        } else {
          item.status = false
        }
      })
      this.total = res.count;
    },
    // 双击展开事件
    dbclick(row, column, event) {
      if (event.currentTarget.querySelector('.el-table__expand-icon')) {
        event.currentTarget.querySelector('.el-table__expand-icon').click()
      }
    },
    // 监听pageSize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize;
      this.getWechartList()
    },
    // 监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getWechartList()
    },
    // 展示赠送对话框
    async showPresent(row) {
      this.editDialogVisible = true;
      const { data: res } = await this.$http.get('charge-user-info/getChargeUserInfoById/' + row.id)
      if (res.code !== 0) {
        row.z_s_anniu = false;
        this.$message.error(res.msg)
      } else {
        row.z_s_anniu = true;
        this.wechartForm = res.data
      }
    },
    // 赠送事件
    editPresent() {
      this.editDialogVisible = false;
      this.$refs.editFormRef.validate(async valid => {
        if (!valid) return
        const id = this.wechartForm.id;
        if (this.num == undefined) return;
          const { data: res } = await this.$http.post('charge-user-info/updGiveMoneyById?id=' + id + '&money=' + this.num * 100 + '&adminId=' + Number(window.sessionStorage.getItem('adminId')))
          if (res.code !== 0) return this.$message.error(res.msg); 
          this.$message.success('赠送成功！');
          this.getWechartList()
      })
    },
    // 赠送对话框的关闭事件
    editDialogClosed() {
      this.num = 0;
      this.editDialogVisible = false;
      this.wechartList.forEach(element => {
        element.z_s_anniu = false
      });
    },

    // 监听pageSize改变的事件
    expendSizeChange(newSize) {
      this.xiaofei.limit = newSize
      this.xiaofei_function()
    },
    // 监听页码值改变的事件
    expendCurrentChange(newPage) {
      this.xiaofei.page = newPage;
      this.xiaofei_function()
    },

    // 展示消费列表对话框
    showExpend(row) {
      this.expendDialogVisible = true;
      this.xiaofei.userNumber = row.userNumber;
      row.x_f_j_l_anniu = true;
      this.xiaofei_function()
    },
    async xiaofei_function() {
      await this.$http.get('charge-user-info/getRecordsList', {
        params: this.xiaofei
      }).then(res => {
        this.xiaofeiTotal = res.data.count;
        this.xiaofeilist = res.data.data;
      })
    },
    async getExpendList() {
      const { data: res } = await this.$http.get('user/getRecordsList', { params: this.xiaofeiInfo })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('获取微信用户消费列表成功！')
      this.expendList = res.data;
      this.expendTotal = res.count;
    },
    // 消费列表对话框的关闭事件
    expendDialogClosed() {
      this.expendDialogVisible = false
      this.expendList = []
    },
    // 批量删除的复选框改变事件
    handleSelectionChange(value) {
      if (value.length > 0) {
        this.ids = ''
        value.forEach(item => {
          this.ids += item.id + ','
        })
        this.ids = this.ids.substring(0, this.ids.length - 1)
      } else {
        this.ids = '';
        return false
      }
    },
    // 删除微信用户方法
    async removeById(row) {
      const confirmResult = await this.$confirm('此操作将永久删除该微信用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已经取消删除！')
      }
      if (row.id === null || row.id === '' || row.id === undefined) {
        const { data: res } = await this.$http.post('user/delUserById/' + this.ids + '/' + window.sessionStorage.getItem('adminId') * 1)
        if (res.code !== 0) {
          return this.$message.error('删除微信用户失败！')
        }
        this.$message.success('删除微信用户成功！')
        this.getWechartList()
      } else {
        const { data: res } = await this.$http.post('user/delUserById/' + row.id + '/' + window.sessionStorage.getItem('adminId') * 1)
        if (res.code !== 0) {
          return this.$message.error('删除微信用户失败！')
        }
        this.$message.success('删除微信用户成功！')
        this.getWechartList()
      }
    }
  }
}
</script>

<style lang="less" scoped></style>
